import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';

document.addEventListener('turbo:load', () => {

  const mainGallerySwiper = document.getElementById('mainGallerySwiper');

  if (mainGallerySwiper) {
    const thumbnailsGallerySwiper = document.getElementById('thumbnailsGallerySwiper');

    Swiper.use([Navigation, Pagination, Scrollbar]);

    // Thumbnails
    let swiperThumbnails;
    let thumbnailsSwiperSlides;
    if (thumbnailsGallerySwiper) {
      thumbnailsSwiperSlides = Array.from(thumbnailsGallerySwiper.getElementsByClassName('swiper-slide'));

      swiperThumbnails = new Swiper(thumbnailsGallerySwiper, {
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        watchSlidesProgress: true,
      });
    }

    // Main Swiper
    const mainSwiper = new Swiper(mainGallerySwiper, {
      spaceBetween: 10,
      slidesPerGroup: 1,

      // Navigation arrows
      navigation: {
        nextEl: '#mainGallerySwiper .swiper-button-next',
        prevEl: '#mainGallerySwiper .swiper-button-prev',
      },

      thumbs: {
        swiper: swiperThumbnails,
      },
    });

    // Fullscreen Modal Swiper
    const modalSwiper = new Swiper('#modalGalleryFullscreen .swiper', {
      spaceBetween: 10,
      slidesPerGroup: 1,

      // Navigation arrows
      navigation: {
        nextEl: '#modalGalleryFullscreen .swiper-button-next',
        prevEl: '#modalGalleryFullscreen .swiper-button-prev',
      },
    });
    const indexGallery = document.getElementById('indexGallery');
    modalSwiper.on('slideChange', function (swiper) {
      indexGallery.innerText = (swiper.activeIndex + 1).toString();
    })

    /**
     * Activa el slider del índice, oscurece el resto de sliders
     * @param { number } index 
     */
    if (thumbnailsSwiperSlides) {
      const activateSlide = (index) => {
        mainSwiper.slideTo(index);
        thumbnailsSwiperSlides.forEach((slide, i) => slide.classList.toggle('active', i == index));
      }

      thumbnailsSwiperSlides.forEach((slide, i) => slide.addEventListener('mouseover', () => activateSlide(i)));
    }

    const modalGalleryFullscreen = new Modal(document.getElementById('modalGalleryFullscreen'), {
      focus: true
    })
    const showGalleryFullscreen = (index) => {
      modalSwiper.slideTo(index);
      modalGalleryFullscreen.show();
    }
    const imgMainGallerySwiper = document.querySelectorAll('#mainGallerySwiper img');
    imgMainGallerySwiper.forEach((img, index) => img.addEventListener('click', () => showGalleryFullscreen(index)));
  }
});