import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';

document.addEventListener('turbo:load', () => {

  Swiper.use([Navigation, Pagination, Scrollbar]);

  const alertsSwiper = document.querySelector('.alerts-swiper');

  if (alertsSwiper) {
    const swiper = new Swiper(alertsSwiper, {
      direction: 'horizontal',
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 25,

      // Navigation arrows
      navigation: {
        nextEl: alertsSwiper.parentElement.querySelector('.swiper-button-next'),
        prevEl: alertsSwiper.parentElement.querySelector('.swiper-button-prev'),
      },
    });
    console.log(swiper);
  }
});
