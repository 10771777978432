const html = document.querySelector('html');

export const showBackdrop = () => {
  html.classList.add('modal-open');
  const backdrop = document.createElement('div');
  backdrop.id = 'backdrop';
  document.body.insertAdjacentElement('beforeend', backdrop);
  return backdrop;
}

export const removeBackdrop = () => {
  html.classList.remove('modal-open');
  document.querySelector('#backdrop').remove();
}