document.addEventListener('turbo:load', () => {
  const showFiltersBtn = document.getElementById('showFilters');

  if (showFiltersBtn) {
    const filtersEl = document.getElementById('filters');
    const closeFiltersElBtn = document.getElementById('closeFilters');
    const html = document.querySelector('html');

    showFiltersBtn.addEventListener('click', () => {
      html.classList.add('modal-open');
      filtersEl.classList.add('show');
    });

    closeFiltersElBtn.addEventListener('click', () => {
      html.classList.remove('modal-open');
      filtersEl.classList.remove('show');
    });
  }
})