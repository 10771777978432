document.addEventListener('turbo:load', () => {
  const showSearchBtn = document.getElementById('showSearchBtn');

  if (showSearchBtn) {
    showSearchBtn.addEventListener('click', () => {
      showSearchBtn.classList.add('d-none');
      console.log(showSearchBtn.nextElementSibling);
      showSearchBtn.nextElementSibling.classList.remove('d-none');
    })
  }
})