import { showBackdrop, removeBackdrop } from '../shared/modal';

document.addEventListener('turbo:load', () => {
  // ============
  // Sidebar Left
  // ============
  const btnOpenSidebarLeft = document.getElementById('btnOpenSidebarLeft');
  const btnCloseSidebarLeft = document.getElementById('btnCloseSidebarLeft');
  const navSidebarLeftEl = document.getElementById('navSidebarLeft');

  if (btnOpenSidebarLeft) {
    const openSidebar = () => {
      navSidebarLeftEl.classList.add('show');
      const backdrop = showBackdrop();
      backdrop.addEventListener('click', () => closeSidebar());
    }
    const closeSidebar = () => {
      navSidebarLeftEl.classList.remove('show');
      removeBackdrop();
    }

    btnOpenSidebarLeft.addEventListener('click', () => openSidebar());
    btnCloseSidebarLeft.addEventListener('click', () => closeSidebar());
  }

  // =============
  // Sidebar Right
  // =============
  const btnOpenSidebarRight = document.getElementById('btnOpenSidebarRight');
  const btnCloseSidebarRight = document.getElementById('btnCloseSidebarRight');
  const navSidebarRightEl = document.getElementById('navSidebarRight');

  if (btnOpenSidebarRight) {
    const openSidebar = () => {
      navSidebarRightEl.classList.add('show');
      const backdrop = showBackdrop();
      backdrop.addEventListener('click', () => closeSidebar());
    }
    const closeSidebar = () => {
      navSidebarRightEl.classList.remove('show');
      removeBackdrop();
    }

    btnOpenSidebarRight.addEventListener('click', () => openSidebar());
    btnCloseSidebarRight.addEventListener('click', () => closeSidebar());
  }

})