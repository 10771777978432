import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import sorteable from "html5sortable/dist/html5sortable.es";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    // this.initSorteable(); Se comenta porque no se puede ordenar por backend (caso solo en edicion)
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  createHiddenInputWithValue(value) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.inputTarget.name;
    input.value = value;
    input.classList.add("picture-item");
    insertAfter(input, this.inputTarget);
    return input;
  }

  initSorteable() {
    this.sorteable = sorteable(".dropzone", {
      hoverClass: "dropzone-move-picture",
      items: ".dz-preview",
      orientation: 'horizontal', // Por defecto es 'vertical'
      forcePlaceholderSize: true
    });

    let controller = this;
    let sortHiddenInputs = function (e) {
      let items = e.detail.origin.items.slice().reverse();

      // Se eliminan los antiguos hidden input para poder ordenarlos
      let oldHiddenInputs = document.getElementsByClassName('picture-item');
      while (oldHiddenInputs.length > 0) {
        oldHiddenInputs[0].parentNode.removeChild(oldHiddenInputs[0]);
      }

      // Se recrean los nuevos hidden inputs ordenados
      for (const item of items) {
        const dzImage = item.querySelector('.dz-image img');
        const dzFileName = item.querySelector('.dz-filename span');
        const signedId = dzImage.getAttribute("data-signed-id");
        const hiddenInput = controller.createHiddenInputWithValue(signedId);
        hiddenInput.setAttribute("data-name", dzFileName.innerText);
      }
    }

    this.sorteable[0].addEventListener('sortupdate', sortHiddenInputs);
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      let hiddenInputByDataName = document.querySelector(`input[data-name='${file.name}']`);

      if (hiddenInputByDataName) {
        removeElement(hiddenInputByDataName);
      }
      else {
        file.controller && removeElement(file.controller.hiddenInput);
      }

      // validateDropZone(this.dropZone);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        const imgElm = this.file.previewElement.querySelector('.dz-image img');
        imgElm.setAttribute("data-signed-id", attributes.signed_id);
        imgElm.setAttribute("data-key", attributes.key);

        this.hiddenInput.value = attributes.signed_id;
        this.hiddenInput.setAttribute("data-key", attributes.key);

        
        this.file.previewElement.addEventListener("click", setMainImage);


        // this.source.initSorteable(); Se comenta porque no se puede ordenar por backend (caso solo en edicion)
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    const afterElm = Array.from(document.querySelectorAll(".picture-item")).pop() || this.source.inputTarget;

    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    input.classList.add("picture-item");
    insertAfter(input, afterElm);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function loadCurrentImages() {
  let myDropzone = this;
  let filesOnServer = document.getElementsByClassName('current-pictures');

  if (filesOnServer.length > 0) {
    // If you only have access to the original image sizes on your server,
    // and want to resize them in the browser:
    // let mockFile = { name: "imagen-1", size: 100 };
    // myDropzone.displayExistingFile(mockFile, "https://images.casas360.pe/store/property-pictures/C3-ba61438cea/thumbnail/9c946e0f43");

    const mainImageInput = document.getElementById("main-image-input");
    const spanPrincipal = document.createElement("span");
    spanPrincipal.classList.add('dz-img-principal');
    spanPrincipal.innerHTML = "⭐ principal";

    // If the thumbnail is already in the right size on your server:
    let index = 1;
    for (const inputPic of filesOnServer) {
      let mockFile = { name: inputPic.getAttribute('data-filename'), size: inputPic.getAttribute('data-size') };
      let callback = null; // Optional callback when it's done
      let crossOrigin = null; // Added to the `img` tag for crossOrigin handling
      let resizeThumbnail = false; // Tells Dropzone whether it should resize the image first
      inputPic.setAttribute("data-name", mockFile.name);
      myDropzone.displayExistingFile(mockFile, inputPic.getAttribute('data-url'), callback, crossOrigin, resizeThumbnail);
      let imgElm = mockFile.previewElement.querySelector('img');
      imgElm.setAttribute("data-signed-id", inputPic.value);
      imgElm.setAttribute("data-key", inputPic.getAttribute('data-key'));

      mockFile.previewElement.addEventListener("click", setMainImage);

      if (mainImageInput.value == inputPic.getAttribute('data-key')){
        mockFile.previewElement.appendChild(spanPrincipal);
      }

      index += 1;
    }

    // If you use the maxFiles option, make sure you adjust it to the
    // correct amount:
    let fileCountOnServer = filesOnServer.length; // The number of files already uploaded
    myDropzone.options.maxFiles = myDropzone.options.maxFiles - fileCountOnServer;
  }
}

function setMainImage() {
  const mainImageInput = document.getElementById("main-image-input");
  const previewElement = this;

  const img = previewElement.querySelector(".dz-image img");
  mainImageInput.value = img.getAttribute("data-key");

  const prevPrincipal = document.querySelector('.dz-img-principal');
  if (prevPrincipal) { prevPrincipal.remove() };

  const spanPrincipal = document.createElement("span");
  spanPrincipal.classList.add('dz-img-principal');
  spanPrincipal.innerHTML = "⭐ principal";
  previewElement.appendChild(spanPrincipal);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    // Translations
    dictDefaultMessage: "Arrastra tus imágenes aqui.",
    dictFallbackMessage: "Tu navegador no soporta arrastrar imágenes.",
    dictFallbackText: "Utiliza este formulario para cargar tus imágenes",
    dictFileTooBig: "El archivo es demasiado grande({{filesize}}Mb). Tamaño máximo: {{maxFilesize}}Mb.",
    dictInvalidFileType: "No puedes subir archivos de este tipo.",
    dictResponseError: "El servidor ha respondido con el código de error: {{statusCode}}",
    dictCancelUpload: "Cancelar subida",
    dictCancelUploadConfirmation: "Estas seguro de cancelar esta subida?",
    dictRemoveFile: "Eliminar",
    dictMaxFilesExceeded: "No puedes subir más archivos.",
    init: loadCurrentImages // Para cargar imagenes ya subidas
  });
}