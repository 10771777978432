document.addEventListener('turbo:load', () => {
  const form = document.getElementById("user_form");

  if (form) {
    const select_role = document.getElementById("user_role");
    const parent_user_container = document.getElementById("parent_user_container");

    select_role.addEventListener('change', function(){
      if(select_role.value == 'miembro-de-familia'){
        parent_user_container.classList.remove('d-none')
      }else{
        parent_user_container.classList.add('d-none')
      }
    })
  }
})