// @ts-check

document.addEventListener('turbo:load', () => {
  const myAccount = document.getElementById('myAccount');
  if (myAccount) {
    /**
     * @type { HTMLInputElement }
     */
    const userPhotoInput = myAccount.querySelector('#user_photo');

    userPhotoInput.addEventListener('change', () => {
      const [imgFile] = userPhotoInput.files;
      if (imgFile) {
        /**
         * @type { HTMLImageElement }
         */
        const newImage = document.querySelector('#newImg');
        newImage.src = URL.createObjectURL(imgFile);
        newImage.classList.remove('d-none');

        document.querySelector('.photo-icon').classList.add('d-none');
        document.querySelector('#prevImg')?.classList.add('d-none');
        document.querySelector('#messageEditPhoto').classList.remove('d-none');
      }
    });
  }
})