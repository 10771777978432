document.addEventListener('turbo:load', () => {
  const adUsersAutocomplete = document.getElementById("ad_users");

  if (adUsersAutocomplete) {
    const adUserList = document.getElementById("user-list");
    const userIdField = document.getElementById("ad_user_id");

    if (userIdField.value != "") {
      const selectedOption = adUserList.querySelector(`[data-id="${userIdField.value}"]`);
      adUsersAutocomplete.value = selectedOption.value;
    }

    const setUserId = (e) => {
      const selectedValue = e.currentTarget.value;
      userIdField.value = null;

      if (selectedValue.trim() != '') {
        const selectedOption = adUserList.querySelector(`[value="${selectedValue}"]`);
        userIdField.value = selectedOption.getAttribute('data-id');
      }
    }

    adUsersAutocomplete.addEventListener("change", setUserId);
  }
})