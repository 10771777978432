// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../js/bootstrap_js_files.js'
import '../js/fontawesome-setup'
import '../js/pwa'

import Rails from "@rails/ujs"

// import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false // Evita que data-remote se ponga siempre en true en links y formularios

import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

require("trix");
require("@rails/actiontext");

require("./shared/trix-no-attach-allowed");
require("./shared/clone_element");

// Frontend
require("./frontend/shared/accept-terms");
require("./frontend/shared/whatsapp-shower");
require("./frontend/shared/emergency-numbers-shower");

// Components
require("./frontend/components/sidebar");
require("./frontend/components/show-search");
require("./frontend/components/filter-list");

// =================== //
// === Views start === //
// =================== //

// Home
require("./frontend/views/home/phones-swiper");
require("./frontend/views/home/items-swiper");

// My Account
require("./frontend/views/my-account/edit-photo");

// Security
require("./frontend/views/security/alerts-swiper");

// Show
require("./frontend/views/show/img-swiper-show");

// ================= //
// === Views end === //
// ================= //

// Admin Panel
require("./admin_panel/user-form");
require("./admin_panel/sort-categories");
require("./admin_panel/sort-projects");
require("./admin_panel/sort-cameras");
require("./admin_panel/sort-phones");
require("./admin_panel/ads-autocomplete");
require("./shared/google-map-input");

// Stimulus Controllers
import 'controllers'
