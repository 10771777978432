import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';

document.addEventListener('turbo:load', () => {

  const numbersSwiperEl = document.querySelector('#numbersSwiper .swiper');

  if (numbersSwiperEl) {
    Swiper.use([Navigation, Pagination, Scrollbar]);

    const swiper = new Swiper(numbersSwiperEl, {
      direction: 'horizontal',
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 30,

      // Navigation arrows
      navigation: {
        nextEl: '#numbersSwiper .swiper-button-next',
        prevEl: '#numbersSwiper .swiper-button-prev',
      },
    });

    const lengthNumberSwipersEl = numbersSwiperEl.querySelectorAll('.swiper-slide').length;
    const wrapperSwiper = numbersSwiperEl.querySelector('.swiper-wrapper');
    const slidePhoneNumberSwiper = numbersSwiperEl.querySelector('.swiper-slide');

    

    /**
     * Calcula el ancho del elemento contenedor y verifica si es necesario centrar los items (En caso haya pocos y quede espacio vacío en el contenedor)
     */
    const calculateSpaceNumberSwiper = () => {
      const widthSwiperSlide = (slidePhoneNumberSwiper.offsetWidth + parseFloat(slidePhoneNumberSwiper.style.marginRight)) || 222;
      const centeredSwiper = numbersSwiperEl.offsetWidth / lengthNumberSwipersEl > widthSwiperSlide;
      wrapperSwiper.classList.toggle('justify-content-center', centeredSwiper);
    }

    calculateSpaceNumberSwiper();
    window.addEventListener('resize', () => calculateSpaceNumberSwiper());
  }
});