document.addEventListener('turbo:load', () => {
  const addElements = () => {
    // Clona (agrega) elementos
    const cloneButtons = document.getElementsByClassName('clone-input-button');

    if (cloneButtons.length > 0) {
      const cloneInput = (e) => {
        e.preventDefault();
        const button = e.currentTarget;
        const elementList = document.querySelectorAll(`.${button.getAttribute('data-to-clone')}`);
        const elementToClone = elementList[elementList.length - 1];

        const clonedElement = elementToClone.cloneNode(true);
        const inputField = clonedElement.querySelector('input');
        inputField.value = '';
        elementToClone.parentNode.insertBefore(clonedElement, elementToClone.nextSibling);
        
        inputField.focus();
        inputField.select();

        removeElements();
      }

      for (let cloneButton of cloneButtons) {
        cloneButton.addEventListener('click', cloneInput);
      }
    }
  }

  const removeElements = () => {
    // Remueve elementos clonados
    const removeButtons = document.getElementsByClassName('remove-clone-element');

    if (removeButtons.length > 0) {
      const removeElement = (e) => {
        e.preventDefault();
        const button = e.currentTarget;

        // Evitar que se borre el ultimo elemento a clonar
        if (removeButtons.length > 1) {
          button.parentNode.remove(); 
        }
      }

      for (let removeButton of removeButtons) {
        removeButton.addEventListener('click', removeElement);
      }
    }
    
  }

  addElements();
  removeElements();
});