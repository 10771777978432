document.addEventListener('turbo:load', () => {
  const enElms = document.getElementsByClassName('emergency-number-event');

  if (enElms.length > 0) {
    const saveImpression = (e) => {
      const enElm = e.currentTarget;
      const itemId = enElm.getAttribute('data-id');
      const csrfToken = document.querySelector("[name='csrf-token']").content;
              
      fetch('/emergency_numbers_showers', {
        method: 'POST',
        headers:  {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          "item_id": itemId
        })
      })
    }

    for (const enElm of enElms) {
      enElm.addEventListener("click", saveImpression);
    }
  }

});
