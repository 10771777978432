import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';

document.addEventListener('turbo:load', () => {

  Swiper.use([Navigation, Pagination, Scrollbar]);

  const itemsHome = document.querySelectorAll('.items-home');

  itemsHome.forEach(item => {
    const swiper = new Swiper(item.querySelector('.swiper'), {
      direction: 'horizontal',
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 25,

      // Navigation arrows
      navigation: {
        nextEl: item.querySelector('.swiper-button-next'),
        prevEl: item.querySelector('.swiper-button-prev'),
      },
    });
  })
});
