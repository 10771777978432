document.addEventListener('turbo:load', () => {
  window.AcceptTermsCheckbox = function(){
    const termsCheckboxs = document.getElementsByClassName('accept-terms');
    
    const toggleDisabledSubmit = (e) => {
      let termsElm = e.currentTarget;
      let contactFormSubmitBtn = document.getElementById(termsElm.getAttribute('data-submit-button'));

      if (contactFormSubmitBtn) {
        contactFormSubmitBtn.disabled = !termsElm.checked;
      }
    }

    for (const termsCheckbox of termsCheckboxs) {
      termsCheckbox.addEventListener("click", toggleDisabledSubmit);
    }
  }
  AcceptTermsCheckbox();
});