import { Loader } from "@googlemaps/js-api-loader";

document.addEventListener('turbo:load', () => {
  const mapElm = document.querySelector('[data-map="canvas"]');
  
  if (mapElm) {
    const latitudeElm = document.querySelector('[data-map="latitude"]');
    const longitudeElm = document.querySelector('[data-map="longitude"]');
    const addressElm = document.querySelector('[data-map="address"]');
    const loadAddressButton = document.querySelector('[data-map="button"]');
    let geocoder;

    const defaultGeoPoint = JSON.parse(mapElm.getAttribute("data-geo-point"));
    const latitude = parseFloat(latitudeElm.value) || defaultGeoPoint.latitude;
    const longitude = parseFloat(longitudeElm.value) || defaultGeoPoint.longitude;

    const loader = new Loader({
      apiKey: mapElm.getAttribute("data-api-key"),
      version: "weekly",
    });

    loader.load().then(() => {
      const location = new google.maps.LatLng(latitude, longitude);

      const map = new google.maps.Map(mapElm, {
        center: location,
        zoom: 17,
      });

      const marker = new google.maps.Marker({
        position: location,
        animation: google.maps.Animation.DROP,
        map: map,
        draggable: true
      });

      const setLatLngInputs = (latlng) => {
        const newlat = ((latlng.lat()*1000000))/1000000;
        const newlng = (Math.round(latlng.lng()*1000000))/1000000;

        latitudeElm.value = newlat;
        longitudeElm.value = newlng;
      };

      // When marker is dragged update input values
      marker.addListener('drag', function() {
        const latlng = marker.getPosition();
        setLatLngInputs(latlng);
      });

      // When drag ends, center (pan) the map on the marker position
      marker.addListener('dragend', function() {
        map.panTo(marker.getPosition());   
      });

      google.maps.event.addListener(map, 'click', function(event) {
        const location = event.latLng;
        marker.setPosition(location);
        setLatLngInputs(location);
        map.panTo(location);
      });

      // Set location from Address
      function setLocationFromAddress() {
        const addressStr = [
          addressElm.value,
          defaultGeoPoint.district,
          defaultGeoPoint.province,
          defaultGeoPoint.region,
          defaultGeoPoint.country
        ].join(", ");
        geocoder = new google.maps.Geocoder();

        geocoder.geocode( { 'address': addressStr}, function(results, status) {
          if (status == 'OK') {
            const addressLocation = results[0].geometry.location;
            marker.setPosition(addressLocation);
            map.setCenter(marker.getPosition());

            latitudeElm.value = addressLocation.lat();
            longitudeElm.value = addressLocation.lng();
          } else {
            console.log('No results: ' + status);
          }
        });
      }
      addressElm.onchange = setLocationFromAddress;
      loadAddressButton.onclick = setLocationFromAddress;
    });
  }
});
