import { Sortable } from 'sortablejs';

document.addEventListener('turbo:load', () => {
  let sortableProjectsElms = document.getElementsByClassName("projects-sortable");

  if (sortableProjectsElms.length > 0) {
    for (let sortableProjectsElm of sortableProjectsElms) {
      new Sortable(sortableProjectsElm, {
        animation: 150,
        onEnd: function (evt) {
          let elements = sortableProjectsElm.querySelectorAll('div.project');

          let arrayElements = []

          elements.forEach(function(element, index){
            arrayElements.push({id: element.getAttribute('data-id'), position: index+1});
          })

          let csrfToken = document.querySelector("[name='csrf-token']").content;

          fetch('/admin_panel/projects/sort_drag_order_number', {
            method: 'POST',
            headers:  {
              "X-CSRF-Token": csrfToken,
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
            body: JSON.stringify({"projects": arrayElements})
          })
        }
      });
    }
  }
})
