import { Sortable } from 'sortablejs';

document.addEventListener('turbo:load', () => {
  let sortableEl = document.getElementById("phones-sortable");

  if (sortableEl)
    new Sortable(sortableEl, {
      animation: 150,
      onEnd: function (evt) {
        let elements = sortableEl.querySelectorAll('.child')

        let arrayElements = []

        elements.forEach(function (element, index) {
          arrayElements.push({ id: element.getAttribute('data-id'), position: index + 1 });
        })

        let csrfToken = document.querySelector("[name='csrf-token']").content;

        fetch('/admin_panel/phones/sort_drag_order_number', {
          method: 'POST',
          headers: {
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
          body: JSON.stringify({ "phones": arrayElements })
        })
      }
    });
})
