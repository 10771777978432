import { Sortable } from 'sortablejs';

document.addEventListener('turbo:load', () => {
  let sortableElms = document.getElementsByClassName("categories-sortable");

  if (sortableElms.length > 0) {
    for (let sortableElm of sortableElms) {
      new Sortable(sortableElm, {
        animation: 150,
        onEnd: function (evt) {
          let elements

          if(sortableElm.getAttribute('data-type') == 'sortable-parent' ){
            elements = sortableElm.querySelectorAll('div.parent')
          }else{
            elements = sortableElm.querySelectorAll('div.child')
          }

          let arrayElements = []

          elements.forEach(function(element, index){
            arrayElements.push({id: element.getAttribute('data-id'), position: index+1});
          })

          let csrfToken = document.querySelector("[name='csrf-token']").content;

          fetch('/admin_panel/categories/sort_drag_order_number', {
            method: 'POST',
            headers:  {
              "X-CSRF-Token": csrfToken,
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
            body: JSON.stringify({"categories": arrayElements})
          })
        }
      });
    }
  }
})
