import 'bootstrap/js/src/alert';
import 'bootstrap/js/src/button';  
import 'bootstrap/js/src/carousel';  
import 'bootstrap/js/src/collapse';  
import 'bootstrap/js/src/dropdown'; 
import Modal from 'bootstrap/js/src/modal';
import 'bootstrap/js/src/popover';  
import 'bootstrap/js/src/scrollspy';  
import 'bootstrap/js/src/tab';
import Toast from 'bootstrap/js/src/toast';  
import Tooltip from 'bootstrap/js/src/tooltip';

// // Acceso para RJS
window.Modal = Modal;
window.Tooltip = Tooltip;
window.Toast = Toast;


document.addEventListener('turbo:load', () => {
  const enableTooltips = () => {
    // Enable Tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    });
  }

  const autoHideToasts = () => {
    // Auto Hide Toasts
    const toastElList = [].slice.call(document.querySelectorAll('.toast'));
    toastElList.map(function (toastEl) {
      const toast = new Toast(toastEl, { delay: 7000 });
      toast.show();
    })
  }
  
  enableTooltips();
  autoHideToasts();
});
